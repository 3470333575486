import React from 'react';
import PageWithHeader from '../components/pageWithHeader';
import style from './holiday2022.module.scss';

const Holiday2022 = () => {

  return (
    <PageWithHeader
      title="Holiday 2022"
      description="Holiday2022">
      <article className={style.mainArticle}>
        <iframe src="https://player.vimeo.com/video/781878314" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
      </article>
    </PageWithHeader>
  );
};

export default Holiday2022;